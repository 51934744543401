import React from 'react';
import mp from './mainpage.module.css'

const MainPage = () => {
    return (
        <div className = {mp.mainPage}>
            <div className ={mp.mainimg}><img src= "../img/Grand-port.png" alt= 'Grandport'></img></div>
            <div className = {mp.slogan}>
                <div className = {mp.slogan_row1}>бизнес холл</div>
                <div className = {mp.slogan_row2}>гранд порт</div>
                <div className = {mp.slogan_row3}>лучшее место для вашего бизнеса</div>
            </div>
        </div>
    )
}


export default MainPage;