import React from 'react';
import MainPage from './components/mainpage/mainpage';
import Description from './components/description/desctiption';
import Rent from './components/rent/rent';
import Offer from './components/offer/offer'
import Callback from './components/callback/callback'
import Contacts from './components/contacts/contacts';
import Footer from './components/footer/footer';
import main from './css/main.module.css';
import './css/fontface.css'
import nav from './components/nav/nav.module.css'
import scrollToComponent from 'react-scroll-to-component';
import Partners from './components/partners/partners';
import Mail from './components/mail/mail';





class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            offer: {display: 'none'},
            callback: {display: 'none'},
            mail: {display: 'none'}
        }
    }
    
    handleOfferClick() {
        this.setState({
            offer: {display: 'grid'}      
        })
    }
    handleCallbackClick() {
        this.setState({
            callback: {display: 'grid'} 
        })
    }
    handleMailClick() {
        this.setState({
            mail: {display: 'grid'}
        })
    }
    handleOfferClickClose () {
        if (this.props.style !== ({display: 'none'}))
        this.setState({
            offer: {display: 'none'} 
        })
    }
    handleCallbackClickClose () {
        if (this.props.style !== ({display: 'none'}))
        this.setState({
            callback: {display: 'none'} 
        })
    }
    handleMailClickclose() {
        if (this.props.style !== ({display: 'none'}))
        this.setState({
            mail: {display: 'none'}
        })
    }

    render() {
        let style = this.state
        const ClickOpenOffer = () => {this.handleOfferClick()}
        const ClickOpenCallback = () => {this.handleCallbackClick()}
        const ClickOpenMail = () => { this.handleMailClick() }
        const ClickCloseOffer = () => { this.handleOfferClickClose() }
        const ClickCloseCallback = () => { this.handleCallbackClickClose() }
        const ClickCloseMail = () => {this.handleMailClickclose()}
        const ScrollToDescription = () => scrollToComponent(this.Description, { offset: 120, align: 'top', duration: 500 })
        const ScrollToRent = () => scrollToComponent(this.Rent, { offset: 160, align: 'top', duration: 500 })
        const ScrollToPartners = () => scrollToComponent(this.Partners, { offset: -90, align: 'top', duration: 500 })
        const ScrollToContacts =() => scrollToComponent(this.Contacts, { offset: -80, align: 'top', duration: 500 })
        return (
            <div>
                <div className={nav.nav}>
                        <div onClick={()=> ScrollToDescription()}>о бизнес центре</div>
                        <div onClick={() => ScrollToRent()}>аренда помещений</div>
                        <div onClick={() => ScrollToPartners()}>наши партнеры</div>
                        <div onClick={() => ScrollToContacts()}>контакты</div>
                </div>
                <Offer style = {style.offer} ClickCloseOffer={ClickCloseOffer} />
                <Callback style = {style.callback} ClickCloseCallback={ClickCloseCallback} />
                <Mail style = {style.mail} ClickCloseMail={ClickCloseMail} />

                
                <div className={main.container}>
                    
                    <section ref={(section) => { this.MainPage = section; }}><MainPage /></section>
                    <section ref={(section) => { this.Description = section; }}><Description /></section>
                    <section ref={(section) => { this.Rent = section; }}><Rent ClickOpenOffer ={ClickOpenOffer} ClickOpenCallback ={ClickOpenCallback} /></section> 
                    <section ref={(section) => { this.Partners = section; }}><Partners /></section>
                    <section ref={(section) => { this.Contacts = section; }}><Contacts /></section>
                
            </div>
                <section className= {main.footer}>
                    <div className={main.shadow}>
                    </div>
                    <Footer ScrollToDescription = {ScrollToDescription} ScrollToRent = {ScrollToRent} 
                            ScrollToPartners = {ScrollToPartners} ScrollToContacts = {ScrollToContacts} 
                            OpenCallback ={ClickOpenCallback} ClickOpenMail={ClickOpenMail}/>
                </section>
            </div>
            
        )
    }
}
export default App