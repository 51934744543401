import React from 'react';
import rent from './rent.module.css';



const Rent = (props) => {

        return (
            <div className={rent.rent}>
                <div className={rent.label}>АРЕНДА ПОМЕЩЕНИЙ</div>
                <div className={rent.action}>
                    <div className={rent.offer}>
                        <div className={rent.text}>Оформите заявку on-line</div>
                        <button onClick={props.ClickOpenOffer} className={rent.button_offer} >оформить заявку</button>
                    </div>
                    <div className={rent.call}>
                        <div className={rent.text}>Или закажите
 обратный звонок</div>
                        <button onClick={props.ClickOpenCallback} className={rent.button_call}>обратный звонок</button>
                    </div>
                </div>
                <div className={rent.adv}>
                    <div className={rent.item}>
                        <div className={rent.img}><img src="../img/map-location.png" alt="map-location" /></div>
                        <div className={rent.descr}>
                            <div className={rent.descr_title}>Удобное расположение</div>
                            <div className={rent.descr_text}>Бизнес центр Гранд Порт находится на пересечении Советского и Бежицкого районов. Постоянный трафик автомобилей и людей обеспечивает высокую проходимость. В шаговой доступности автобусная остановка, а также удобный заезд на автомобиле.</div>
                        </div>

                    </div>
                    <div className={rent.item}>
                        <div className={rent.img}><img src="../img/safe-money.png" alt="" /></div>
                        <div className={rent.descr}>
                            <div className={rent.descr_title}>Выгодные условия</div>
                            <div className={rent.descr_text}>Цены на арендуемые площади в бизнес центре Гранд Порт одни из самых выгодных по городу Брянск. Для получения более подробной информации закажите обратный звонок или свяжитесь с нами любым удобным для Вас способом.</div>
                        </div>

                    </div>
                    <div className={rent.item}>
                        <div className={rent.img}><img src="../img/parking.png" alt="" /></div>
                        <div className={rent.descr}>
                            <div className={rent.descr_title}>Инфраструктура</div>
                            <div className={rent.descr_text}>К вашим услугам круглосуточная охраняемая парковка, охрана и видеонаблюдение за зданием. В здании находится фитнес центр. Есть возможность установки Вашей рекламы на фасад здания.</div>
                        </div>

                    </div>
                </div>

            </div>
        )
    }



export default Rent;