import React from 'react';
import f from './footer.module.css'

const Footer = (props) => {
    return (
        <div className={f.container}>
            <div className={f.links}>
                <div onClick={() => props.ScrollToDescription()} className={f.link}>о бизнес центре</div>
                <div onClick={() => props.ScrollToRent()} className={f.link}>аренда помещений</div>
                <div onClick={() => props.ScrollToPartners()} className={f.link}>наши партнеры</div>
                <div onClick={() => props.ScrollToContacts()} className={f.link}>контакты</div>
            </div>
            <div className={f.social}>
                <div className={f.wrapper}><img src="../img/footer/line_left.png" alt=""/></div>
                <div onClick = {() => props.ClickOpenMail()} className={f.wrapper}><img src="../img/footer/mail.png" alt=""/></div>
                <div onClick = {() => props.OpenCallback()} className={f.wrapper}><img src="../img/footer/phone.png" alt=""/></div>
                <div className={f.wrapper}><a href = 'https://vk.com/grandport'><img src="../img/footer/vk.png" alt=""/></a></div>
                <div className={f.wrapper}><img src="../img/footer/line_right.png" alt=""/></div>
            </div>
            <div className={f.rights}>2020 Все права защищены</div>
        </div>
)

}

export default Footer;