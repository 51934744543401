
import React, { Component } from 'react';
import Slider from 'react-slick'
import './sliderDescr.css'
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css"; 


export default class CenterMode extends Component {
    render() {
        const settings = {
            customPaging: function (i) {
                return (
                    <div>
                        <img src={`../img/slider/${i + 1}.jpg`} alt ={i} />        
                    </div>
                );
            },
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            dotsClass: "slick-dots slick-thumb",
            nextArrow: <div className=""><div className="next"><div className="arrow1"></div><div className="arrow2"></div></div></div> ,
			prevArrow: <div className=""><div className="prev"><div className="arrow3"></div><div className="arrow4"></div></div></div> 

        };
        return (
            <div className="container_descr">

                <Slider {...settings}>
                    <div>
                        <img src={'../img/slider/1.jpg'} alt ='1'  />
                    </div>
                    <div>
                        <img src={'../img/slider/2.jpg'} alt ='2'  />
                    </div>
                    <div>
                        <img src={'../img/slider/3.jpg'} alt ='3'  />
                    </div>
                    <div>
                        <img src={'../img/slider/4.jpg'} alt ='4'  />
                    </div>
                    <div>
                        <img src={'../img/slider/5.jpg'} alt ='5'  />
                    </div>
                </Slider>
            </div>
        );
    }
}
