import React from 'react';
import s from './partners.module.css'
import FocusOnSelect from '../slider/sliderPartners'

const Partners = () => {
    return <div>
        <div className={s.label}>уже с нами</div>
        <div className={s.slider}><FocusOnSelect /></div>
        <div className="">
            <div className="">
            </div>
        </div>
    </div>
}

export default Partners;