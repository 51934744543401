import React from 'react';
import cont from './contacts.module.css'

const Contacts = () => {
    return <div>
        <div className={cont.label}>контакты</div>
        <div className={cont.wrapper}>
            <div className={cont.contacts}>
                <div className={cont.section}>
                    <div className={cont.title}>Адрес</div>
                    <div className={cont.text}>Бизнес-центр “Гранд-Порт”
Бежицкая ул., 286, Брянск, Россия</div>
                </div>
                <div className={cont.section}>
                    <div className={cont.title}>Телефон</div>
                    <div className={cont.text}>+7(960)552-90-80 <br/>
                    +7(960)552-90-85</div>
                </div>
                <div className={cont.section}>
                    <div className={cont.title}>e-mail</div>
                    <div className={cont.text}>info@grandport.ru</div>
                </div>
            </div>
            <div className={cont.map}>
            <iframe title="map" src="https://yandex.ru/map-widget/v1/?um=constructor%3Ab1eddbc550c293246885ebda2e348d8ca6b5538203d1d312a14a8ddbfdcc27b2&amp;source=constructor" width="100%" height="554" frameborder="0"></iframe>
            </div>
        </div>
    </div>
}

export default Contacts;