
import React from "react";
import { useFormik } from "formik";
import mail from './mail.module.css';
import emailjs from 'emailjs-com';




const SignupForm = (props) => {
    function sendEmail(values) {
        values.preventDefault();
    
        emailjs.sendForm('service_gwt39qg', 'template_2jibupt', values.target, 'user_m9XvUjQYsG8Dsnpb4w5im')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          alert('Ваше сообщение успешно отправлено!')
        }  
    const formik = useFormik({
        initialValues: {
            firstName: "",
            telefonNumber: "",
            message: "",
            email: "",
            stair: "",
            size: "",
        },
    });
    return (
        <div className={mail.overlay} style={props.style}>
            <div className={mail.closeFieldRight} onClick={props.ClickCloseMail}></div>
            <form className={mail.box} onSubmit={sendEmail}>
                <label className={mail.text} htmlFor="firstName">Имя*</label>
                <input
                    className={mail.input}
                    id="firstName"
                    name="firstName"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    required
                />

                <label className={mail.text} htmlFor="email">e-mail</label>
                <input
                    className={mail.input}
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    required

                /> 
                <label className={mail.text} htmlFor= "message">Ваше сообщение</label>
                <textarea className={mail.textarea}
                    rows="10"
                    maxlength="150"
                    id= "message"
                    name = "message"
                    onChange={formik.handleChange}
                    value={formik.values.message}>
                        
                    
                </textarea>
           
            
                <button className={mail.btn} onClick={props.ClickCloseMail} type="submit">Отправить сообщение</button>
            </form>

            <div className={mail.closeFieldLeft} onClick={props.ClickCloseMail}>
            <div className={mail.close} onClick={props.ClickCloseMail} >
                ×
            </div>
            </div>
        
        </div>
    );
};

export default SignupForm