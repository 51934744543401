import React, { Component } from 'react';
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ptSlider.css'

export default class FocusOnSelect extends Component {
	render() {
		const settings = {
			centerMode: false,
			arrows: true,
			focusOnSelect: true,
			infinite: true,
			slidesToShow: 2,
			slidesToScroll: 1,
			speed: 1000,
			nextArrow: <div className=""><div className="next"><div className="arrow1"></div><div className="arrow2"></div></div></div>,
			prevArrow: <div className=""><div className="prev"><div className="arrow3"></div><div className="arrow4"></div></div></div>
		};
		return (
			<div className="container_part">

				<Slider {...settings}>
					<div className="el">
						<img src={'../img/slider-part/1.jpg'} alt="Garmony" />
					</div>
					<div className="el">
						<img src={"../img/slider-part/3.jpg"} alt="nonstop" />
					</div>
					<div className="el">
						<img src={"../img/slider-part/Logo.jpg"} alt="no-dept" />
					</div>
				</Slider>
			</div>
		);
	}
}
