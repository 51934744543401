import React from 'react';
import { useFormik } from "formik";
import of from './callback.module.css';
import emailjs from 'emailjs-com';

const CallBack = (props) => {
    function sendEmail(values) {
        values.preventDefault();
    
        emailjs.sendForm('service_gwt39qg', 'template_2jibupt', values.target, 'user_m9XvUjQYsG8Dsnpb4w5im')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          alert('Ваша заявка успешно отправлена!')
        }
    const formik = useFormik({
        initialValues: {
            firstName: "",
            telefonNumber: "",
            email: ""
        },
    });
    return (
        <div className={of.overlay} style={props.style}>
            <div className={of.closeFieldRight} onClick={props.ClickCloseCallback}></div>
            <form className={of.box} onSubmit={sendEmail}>
                <label className={of.text} htmlFor="firstName">Имя*</label>
                <input
                    className={of.input}
                    id="firstName"
                    name="firstName"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    required
                />
                <label className={of.text} htmlFor="telefonNumber">Номер телефона*</label>
                <input
                    className={of.input}
                    id="telefonNumber"
                    name="telefonNumber"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.telefonNumber}
                    required
                />
                <label className={of.text} htmlFor="email">e-mail</label>
                
                <input
                    className={of.input}
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                />

                <div className={of.text}>
                <p>Заполните заявку и мы свяжемся с Вами в ближайшее время и ответим на Ваши вопросы</p>
                </div>
                
                
                <button className={of.btn} onClick={props.ClickCloseOffer} type="submit">Обратный звонок</button>
            </form>
            <div className={of.closeFieldLeft} onClick={props.ClickCloseCallback}>
            <div className={of.close} onClick={props.ClickCloseCallback} >
                    ×
            </div>
            </div>
        </div>
    );
};


export default CallBack
