
import React from 'react';
import descr from './description.module.css'
import Slider from '../slider/sliderDescr'


const Description = () => {
    return <div className={descr.descr}>
        <div className={descr.label}>о бизнес центрe</div>
        <div className={descr.block}>
            <div className={descr.title}>Современный комфортный бизнес центр для вашего бизнеса и отдыха</div>
            <div className={descr.text}>Бизнес Центр Гранд-Порт предоставляет возможность аренды офисных помещений от собственника, полностью готовых к въезду. Офисные помещения БЦ имеют коридорно-кабинетную планировку и предоставляются с отделкой. На цокольном этаже распологается фитнес-центр, на этажах с 1 по 3 находятся офисы различной планировки и метража. На 4-ом этаже находится помещение под кафе. Офисы оборудованы приточно-вытяжной вентиляцией, пожарной системой, системой оповещения и счетчиками учета электроэнергии. Видеонаблюдение, круглосуточный доступ на территорию. Собственные службы: безопасности, эксплуатации, котельная.</div>
        </div>
        <div className={descr.slider}><Slider /></div>
    </div>
}
export default Description;